jQuery(function($) {
  // Remove the 'no-js' <body> class
  $("html").removeClass("no-js");

  //Disable pointer events on scroll
  var disablePointerEventsOnScroll = function() {
    var root = document.documentElement;
    var timer;

    window.addEventListener(
      "scroll",
      function() {
        // User scrolling so stop the timeout
        clearTimeout(timer);
        // Pointer events has not already been disabled.
        if (!root.style.pointerEvents) {
          root.style.pointerEvents = "none";
        }

        timer = setTimeout(function() {
          root.style.pointerEvents = "";
        }, 250);
      },
      false
    );
  };
  disablePointerEventsOnScroll();
});

// ToggleAttribute polyfill for IE
if (!Element.prototype.toggleAttribute) {
  Element.prototype.toggleAttribute = function(name, force) {
    if(force !== void 0) force = !!force 
    
    if (this.hasAttribute(name)) {
      if (force) return true;

      this.removeAttribute(name);
      return false;
    }
    if (force === false) return false;
      
    this.setAttribute(name, "");
    return true;
  };
}